import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-scroll';
import './App.scss';

function App() {
  const [ScrollY, setScrollY] = useState(0);
  const [BtnStatus, setBtnStatus] = useState(false);

  const handleFollow = () => {
    setScrollY(window.pageYOffset);
    if (ScrollY > 50) {
      setBtnStatus(true);
    } else {
      setBtnStatus(false);
    }
  };
  const [mMenuDepth, setmMenuDepth] = useState(false);
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error('Error attempting to play', error);
      });
  };

  const handleTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setScrollY(0);
    setBtnStatus(false);
  };

  useEffect(() => {
    attemptPlay();
  }, []);
  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    };
    watch();
    return () => {
      window.removeEventListener('scroll', handleFollow);
    };
  });
  return (
    <div className="App">
      <div className="body">
        <div className="header">
          <div className="inner">
            <div className="nav_box">
              <h1 className="logo">
                <img src="/images/logo.png" alt="logo" />
              </h1>
              <div className="nav">
                <ul>
                  <Link className="one_d" to="0" spy={true} smooth={true}>
                    박람회 소개
                  </Link>
                  <Link className="one_d" to="1" spy={true} smooth={true}>
                    참가신청
                  </Link>
                  <p
                    className="one_d"
                    onClick={() => {
                      window.open('https://freelancerexpo.oopy.io/');
                    }}>
                    이벤트 소개
                  </p>
                  <Link
                    onClick={() => {
                      window.open('https://zep.us/play/yaMpVP');
                    }}
                    className="metaverse_btn one_d">
                    메타버스 입장하기
                  </Link>
                </ul>
              </div>
            </div>
            <div className="m_nav_box">
              <div
                className="nav_btn"
                onClick={() => {
                  setmMenuDepth(!mMenuDepth);
                }}></div>
              <ul className={mMenuDepth === true ? 'on' : ''}>
                <div
                  spy={true}
                  smooth={true}
                  onClick={() => {
                    setmMenuDepth(!mMenuDepth);
                  }}
                  className="close_btn"></div>
                <Link className="one_d" to="0" spy={true} smooth={true}>
                  박람회 소개
                </Link>
                <Link className="one_d" to="1" spy={true} smooth={true}>
                  참가신청
                </Link>
                <Link
                  className="one_d"
                  onClick={() => {
                    window.open('https://freelancerexpo.oopy.io/');
                  }}>
                  이벤트 소개
                </Link>
                <Link
                  onClick={() => {
                    window.open('https://zep.us/play/yaMpVP');
                  }}
                  className="metaverse_btn one_d">
                  메타버스 입장하기
                </Link>
              </ul>
            </div>
          </div>
          <div className="header_main">
            <div className="header_title">
              <img src="/images/header_tit.svg" alt="상단 타이틀" />
              <img className="m_header_title" src="/images/m_header_tit.svg" alt="상단 타이틀 모바일" />
            </div>
            <div className="inner">
              <div className="txt">
                <img src="/images/header_txt.svg" alt="txt" />
                <p className="data_txt">
                  <img className="header_title_date" src="/images/header_title_date.svg" alt="상단 타이틀 모바일" />
                </p>
              </div>
              <div className="letter_position">
                <div className="arrow_box"></div>
                <div
                  className="letter_box"
                  onClick={() => {
                    window.open('https://zep.us/play/yaMpVP');
                  }}></div>
              </div>
            </div>
          </div>

          <div className="man"></div>
          <div className="woman"></div>
        </div>
        <div className="main">
          <div className="paly_box_bg">
            <div className="inner">
              <div className="paly_box">
                <div className="paly_vod">
                  <video style={{ height: '100%', width: '100%' }} playsInline loop muted controls alt="All the devices" src="/videos/paly_vod.mp4" ref={videoEl} type="video/mp4" />
                </div>
              </div>
            </div>
          </div>
          <div className="summary_box">
            <div className="inner">
              <div className="summary_txt">
                <img src="/images/summary_txt.svg" alt="개요" />
              </div>
            </div>
          </div>
          <div className="info_box" id="0">
            <img className="title" src="/images/info_tit.svg" alt="박람회 소개 타이틀" />
            <div className="inner">
              <img className="m_title" src="/images/m_info_tit.svg" alt="박람회 소개 타이틀 모바일" />
            </div>
            <div className="info_box_bg">
              <div className="inner">
                <div className="info_box">
                  <div className="info_list_box">
                    <div className="list_bg_1 list_bg"></div>
                    <div className="list_bg_2 list_bg"></div>
                    <div className="list_bg_3 list_bg"></div>
                    <div className="list_bg_4 list_bg"></div>
                  </div>
                </div>
              </div>
              <div className="buttons" id="1">
                <button type="button" onClick={() => window.open('https://forms.gle/kwfkThfFJ5CLPCiPA', '_blank')} className="attend_btn">
                  <p>기업 참가 신청하기</p>
                </button>
                <button type="button" onClick={() => window.open(' https://forms.gle/iBYzetUaVqkMbqdN7', '_blank')} className="attend_btn">
                  <p>프리랜서 참가 신청하기</p>
                </button>
              </div>
            </div>
          </div>
          <div className="event_box">
            <div className="inner">
              <div className="time_table">
                <img src="/images/time_table.png" alt="세미나 타임테이블" />
                <span className="lf_i"></span>
                <span className="top_i"></span>
                <span className="bottom_i"></span>
              </div>
              <div className="work_day_box">
                <img src="/images/work_day_title.png" className="event_title" alt="워크 Day" />
                <div className="work_day_list">
                  <img style={{ marginBottom: '42px' }} className="pc_img" src="/images/work_day_event1.png" alt="프리랜서를 위한 똑똑한 브랜딩 전략" />
                  <img style={{ marginBottom: '42px' }} className="mo_img" src="/images/mo_work_day_event1.png" alt="프리랜서를 위한 똑똑한 브랜딩 전략" />
                  <img src="/images/work_day_event2.png" className="pc_img" alt="워크 show, '프리'한 일잘러들의 셩장 토크쇼" />
                  <img src="/images/mo_work_day_event2.png" className="mo_img" alt="워크 show, '프리'한 일잘러들의 셩장 토크쇼" />
                  <span className="work_lf_i"></span>
                  <span className="work_bottom_i"></span>
                </div>
              </div>
              <div className="life_day_box">
                <img src="/images/life_day_title.png" className="event_title" alt="라이프 Day" />
                <div className="life_day_list">
                  <img style={{ marginBottom: '42px' }} className="pc_img" src="/images/life_day_event1.png" alt="프리랜서를 위한 똑똑한 브랜딩 전략" />
                  <img style={{ marginBottom: '42px' }} className="mo_img" src="/images/mo_life_day_event1.png" alt="프리랜서를 위한 똑똑한 브랜딩 전략" />
                  <img src="/images/life_day_event2.png" className="pc_img" alt="워크 show, '프리'한 일잘러들의 셩장 토크쇼" />
                  <img src="/images/mo_life_day_event2.png" className="mo_img" alt="워크 show, '프리'한 일잘러들의 셩장 토크쇼" />
                  <span className="life_lf_i"></span>
                  <span className="life_bottom_i"></span>
                </div>
              </div>
            </div>
            <div className="bottom_img">
              <img src="/images/img_bottom.png" alt="바텀 이미지" />
            </div>
          </div>
          <div className="partner_info" id="2">
            <div className="inner">
              <div className="support-box">
                <div className="title-box">
                  <img src="/images/partner_info_title.png" alt="홍보" />
                </div>
                <div className="list-box">
                  <div>
                    <div className="item">
                      <img src="/images/support_1.png" alt="벤처기업혐회" />
                    </div>
                    <div className="item">
                      <img src="/images/support_2.png" alt="김해의생명산업진흥원" />
                    </div>
                  </div>
                  <div>
                    <div className="item">
                      <img src="/images/support_3.png" alt="청년창업네트워크" />
                    </div>
                    <div className="item">
                      <img src="/images/support_4.png" alt="이벤트 플러스" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="company-box">
                <div className="title-box">
                  <img src="/images/company_title.png" alt="참여기업" />
                </div>
                <div className="list-box">
                  <div className="item">
                    <img src="/images/company_1.png" alt="긱플러스" />
                  </div>
                  <div className="item">
                    <img src="/images/company_2.png" alt="디딤스토리" />
                  </div>
                  <div className="item">
                    <img src="/images/company_3.png" alt="(주)탭핀" />
                  </div>
                  <div className="item">
                    <img src="/images/company_4.png" alt="마이샵온샵" />
                  </div>
                  <div className="item">
                    <img src="/images/company_5.png" alt="(주)애드투믹스" />
                  </div>
                  <div className="item">
                    <img src="/images/company_6.png" alt="청년찹업네트워크" />
                  </div>
                  <div className="item">
                    <img src="/images/company_7.png" alt="인트윈" />
                  </div>
                  <div className="item">
                    <img src="/images/company_8.png" alt="핏닥" />
                  </div>
                  <div className="item">
                    <img src="/images/company_9.png" alt="지금과 깃듬" />
                  </div>
                  <div className="item">
                    <img src="/images/company_10.png" alt="(주)트렌베리" />
                  </div>
                  <div className="item">
                    <img src="/images/company_11.png" alt="주식회사 오몽" />
                  </div>
                  <div className="item">
                    <img src="/images/company_12.png" alt="이퓨월드" />
                  </div>
                  <div className="item">
                    <img src="/images/company_13.png" alt="팀플백" />
                  </div>
                  <div className="item">
                    <img src="/images/company_14.png" alt="크레아코퍼레이션" />
                  </div>
                  <div className="item">
                    <img src="/images/company_15.png" alt="메타포" />
                  </div>
                  <div className="item">
                    <img src="/images/company_16.png" alt="소노연구소" />
                  </div>
                  <div className="item">
                    <img src="/images/company_17.png" alt="플러스닷" />
                  </div>
                  <div className="item">
                    <img src="/images/company_18.png" alt="주식회사 롸잇" />
                  </div>
                  <div className="item">
                    <img src="/images/company_19.png" alt="피플앤스토리" />
                  </div>
                  <div className="item">
                    <img src="/images/company_20.png" alt="글로벌미래교육원" />
                  </div>
                  <div className="item">
                    <img src="/images/company_21.png" alt="솔루니" />
                  </div>
                  <div className="item">
                    <img src="/images/company_22.png" alt="라이브온미" />
                  </div>
                  <div className="item">
                    <img src="/images/company_23.png" alt="마이티셀" />
                  </div>
                  <div className="item">
                    <img src="/images/company_24.png" alt="에스엔제이컴퍼니" />
                  </div>
                  <div className="item">
                    <img src="/images/company_25.png" alt="(주)에이엔이광개토" />
                  </div>
                  <div className="item">
                    <img src="/images/company_26.png" alt="리바이K" />
                  </div>
                  <div className="item">
                    <img src="/images/company_27.png" alt="바로그식품" />
                  </div>
                  <div className="item">
                    <img src="/images/company_28.png" alt="주식회사더하이픈" />
                  </div>
                  <div className="item">
                    <img src="/images/company_29.png" alt="액스플랫" />
                  </div>
                  <div className="item">
                    <img src="/images/company_30.png" alt="(주)도쉼" />
                  </div>
                  <div className="item">
                    <img src="/images/company_31.png" alt="LEDSOFT" />
                  </div>
                  <div className="item">
                    <img src="/images/company_36.png" alt="(주)온마플랫폼" />
                  </div>
                  <div className="item">
                    <img src="/images/company_32.png" alt="엔젤안전교육원(EHRD)" />
                  </div>
                  <div className="item">
                    <img src="/images/company_33.png" alt="리봄교육" />
                  </div>
                  <div className="item">
                    <img src="/images/company_34.png" alt="애드모션(주)" />
                  </div>
                  <div className="item">
                    <img src="/images/company_35.png" alt="피앤아이라이브" />
                  </div>
                  <div className="item">
                    <img src="/images/company_38.png" alt="휴식습관" />
                  </div>
                  <div className="item">
                    <img src="/images/company_37.png" alt="플라이트" />
                  </div>
                  <div className="item">
                    <img src="/images/company_39.jpeg" alt="크레텀(코치미)" />
                  </div>
                  <div className="item">
                    <img src="/images/company_40.jpeg" alt="irum" />
                  </div>
                  <div className="item">
                    <img src="/images/company_41.jpeg" alt="강사인포" />
                  </div>
                  <div className="item">
                    <img src="/images/company_42.jpeg" alt="works" />
                  </div>
                  <div className="item">
                    <img src="/images/company_43.jpeg" alt="boem" />
                  </div>
                  <div className="item">
                    <img src="/images/company_44.jpeg" alt="mingling" />
                  </div>
                  <div className="item">
                    <img src="/images/company_45.jpeg" alt="MR" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={BtnStatus ? 'scroll-top active' : 'scroll-top'} onClick={handleTop}>
          <span></span>
        </div>
      </div>
    </div>
  );
}

export default App;
